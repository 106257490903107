import { createSelector } from 'reselect';
import { orderByAlphabetic } from '../utils/orderByAlphabetic';
import {
  getProductRecipesCostErrors,
  getProductRecipesTotalCost,
  getProductsRecipesTotalCost,
} from './productsRecipeSelectors';
import {
  getProductSuppliesCostErrors,
  getProductSuppliesTotalCost,
  getProductsSuppliesTotalCost,
} from './productsSupplySelectors';
import orderByValue from '../utils/orderByValue';
import {
  getProductBundlesCostErrors,
  getProductBundlesTotalCost,
  getProductsBundlesTotalCost,
} from './productsBundleSelectors';
import { getIncompleteProductCategoriesIds } from './configSelectors';

const getProducts = (state) => state.entities.products;
const getId = (_, id) => id;
const getProductsList = (state) => state.productsList;
const getFetchedIds = (state) => state.productsList.productIds;

export const getProductPageInfo = createSelector(
  getProductsList,
  (productsList) => ({
    currentPage: productsList.currentPage,
    totalPages: productsList.totalPages,
    filters: productsList.filters,
    loading: productsList.loading,
  }),
);

export const getAllProducts = createSelector(getProducts, (products) =>
  Object.values(products).sort(orderByAlphabetic),
);

export const getProductsForBundles = createSelector(
  getAllProducts,
  getId,
  (products, id) =>
    products.filter((product) => {
      if (!product.isBundle) return true;
      if (!!id && product?.id != id) return true;

      return false;
    }),
);

export const getProductsForCalculator = createSelector(
  getAllProducts,
  getIncompleteProductCategoriesIds,
  (products, ids) =>
    products
      .filter((product) => !ids?.includes(product?.productCategoryId))
      .sort(orderByAlphabetic),
);

export const getProduct = createSelector(
  getProducts,
  getId,
  (products, id) => products[id],
);

export const getProductsByFetchedIds = createSelector(
  getAllProducts,
  getFetchedIds,
  (products, ids) =>
    products
      .filter((product) => ids.includes(product.id))
      .sort(orderByAlphabetic),
);

export const getTopProducts = createSelector(
  getAllProducts,
  getFetchedIds,
  (products, ids) =>
    products
      .filter((product) => ids.includes(product.id))
      .sort(orderByValue('soldAmount'))
      .slice(0, 3),
);

export const getProductsCostErrors = createSelector(
  getProductRecipesCostErrors,
  getProductSuppliesCostErrors,
  getProductBundlesCostErrors,
  (pR, pS, bI) => {
    if (!pR && !pS && !bI) return false;

    const recipesErrors = !!pR.length
      ? pR?.map(({ name }) => `\n- Receita: ${name}`)
      : '';

    const suppliesErrors = !!pS.length
      ? pS?.map(({ name }) => `\n- Insumo: ${name}`)
      : '';

    const productsErrors = !!bI.length
      ? bI?.map(({ name }) => `\n- Produto: ${name}`)
      : '';

    const suppliesHint = suppliesErrors
      ? '\n\nDica: Verifique se você já adicionou itens em estoque para este(s) insumo(s) com o preço correspondente.'
      : '';

    let errors = `O custo estimado do produto não pôde ser calculado devido à impossibilidade de calcular o custo dos seguintes itens de produtos, receitas ou insumos:\n${productsErrors}${recipesErrors}${suppliesErrors}${suppliesHint}`;
    errors = errors.replace(/,/g, '');

    return errors;
  },
);

export const getProductsTotalCost = createSelector(
  getProductsBundlesTotalCost,
  getProductsSuppliesTotalCost,
  getProductsRecipesTotalCost,
  (productsCost, suppliesCost, recipesCost) =>
    productsCost + suppliesCost + recipesCost,
);

export const getProductTotalCost = createSelector(
  getProductBundlesTotalCost,
  getProductSuppliesTotalCost,
  getProductRecipesTotalCost,
  (productsCost, suppliesCost, recipesCost) =>
    productsCost + suppliesCost + recipesCost,
);
