import { toArray } from '../utils/toArray';

const initialState = {
  statementIds: [],
  loading: false,
  totalPages: 0,
  currentPage: 1,
  ordersAverageAmount: 0,
  ordersAverageProfitMargin: 0,
  totalProductsStockAmount: 0,
  productSalesByCategory: [],
  ordersReasonsRanking: [],
  expensesByCategory: [],
};

export default function statementsListReducer(state = initialState, action) {
  switch (action.type) {
    case 'STATEMENT_FETCH_REQUESTED':
    case 'STATEMENTS_FETCH_REQUESTED':
      return { ...state, loading: true };
    case 'STATEMENTS_FETCHED':
      return {
        ...state,
        loading: false,
        totalPages: action.totalPages,
        currentPage: action.currentPage,
        statementIds: action.result
          ? toArray(action.result)
          : initialState.statementIds,
      };
    case 'STATEMENTS_PAGE_CHANGED':
      return {
        ...state,
        currentPage: action.currentPage,
      };
    case 'STATEMENTS_FILTERS_CHANGED':
      return {
        ...state,
        filters: action.filters,
        totalPages: action.totalPages,
        currentPage: 1,
      };
    case 'GENERAL_DATA_FETCHED':
      return {
        ...state,
        ordersAverageAmount: action.data.ordersAverageAmount,
        totalProductsStockAmount: action.data.totalProductsStockAmount,
        ordersAverageProfitMargin: action.data.ordersAverageProfitMargin,
        productSalesByCategory: action.data.productSalesByCategory,
        ordersReasonsRanking: action.data.ordersReasonsRanking,
      };
    case 'EXPENSE_CATEGORIES_RANKING_FETCHED':
      return {
        ...state,
        expensesByCategory: action.data.expensesByCategory,
      };
    default:
      return state;
  }
}
