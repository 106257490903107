import { createSelector } from 'reselect';
import orderByValue from '../utils/orderByValue';

const getConfigs = (state) => state.entities.configs;
const getConfigName = (_, name) => name;
const getConfigId = (_, __, id) => id;

export const getConfig = createSelector(
  getConfigs,
  getConfigName,
  (configs, name) => {
    const config = configs[name];
    return config && Object.values(config);
  },
);

export const getConfigByName = createSelector(
  getConfigs,
  getConfigName,
  (configs, name) => configs[name],
);

export const getConfigById = createSelector(
  getConfig,
  getConfigId,
  (collection, id) => collection?.filter((item) => item.id === id)[0],
);

export const getCountries = createSelector(getConfigs, (configs) => {
  return Object.values(configs['countries']);
});

export const getProductCategories = createSelector(getConfigs, (configs) => {
  return Object.values(configs['productCategories']);
});

export const getIncompleteProductCategoriesIds = createSelector(
  getConfigs,
  (configs) => configs['incompleteProductCategoriesIds'],
);

export const getCurrencies = createSelector(getConfigs, (configs) => {
  return Object.values(configs['currencies']);
});

export const getCountriesNames = createSelector(getCountries, (countries) => {
  return countries.map(({ id, name }) => {
    return { id, name: name };
  });
});

export const getCurrenciesNames = createSelector(
  getCurrencies,
  (currencies) => {
    return currencies.map(({ id, name, symbol }) => {
      return { id, name: `${name} (${symbol})` };
    });
  },
);

export const getStateUfs = createSelector(getConfigs, (configs) => {
  return Object.values(configs['states']).map(({ id, uf }) => {
    return { id, name: uf };
  });
});

export const getUnits = createSelector(getConfigs, (configs) => {
  return Object.values(configs['units']);
});

export const getUnitOptions = createSelector(getUnits, (units) => {
  return units.map((unit) => {
    return {
      text: unit.abbreviation,
      value: unit.id,
    };
  });
});

export const getSupplyCategories = createSelector(getConfigs, (configs) => {
  return Object.values(configs['supplyCategories']);
});

export const getSupplyCategoryOptions = createSelector(
  getSupplyCategories,
  (supplyCategories) => {
    return supplyCategories.map((supplyCategory) => {
      return {
        text: supplyCategory.name,
        value: supplyCategory.id,
      };
    });
  },
);

export const getUnitById = createSelector(
  getUnits,
  getConfigName,
  (units, id) => units.find((unit) => unit.id == id),
);

export const getCountryById = createSelector(
  getCountries,
  getConfigName,
  (countries, id) => countries.find((country) => country.id == id),
);

export const getCurrencyById = createSelector(
  getCurrencies,
  getConfigName,
  (currencies, id) => currencies.find((currency) => currency.id == id),
);

export const getCurrencyByCode = createSelector(
  getCurrencies,
  getConfigName,
  (currencies, code) => currencies.find((currency) => currency.code == code),
);

export const getStrategicRoles = createSelector(getConfigs, (configs) => {
  return Object.values(configs['strategicRoles']);
});

export const getStrategicRoleById = createSelector(
  getStrategicRoles,
  getConfigName,
  (strategicRoles, id) =>
    strategicRoles.find((strategicRole) => strategicRole.id == id),
);

export const getStatementCategories = createSelector(getConfigs, (configs) => {
  return Object.values(configs['statementCategories']);
});

export const getStatementCategoriesByType = createSelector(
  getStatementCategories,
  getConfigName,
  (statementCategories, type) =>
    statementCategories.filter(
      (statementCategory) => statementCategory[type] == true,
    ),
);

export const getProductsCategoriesForCalculator = createSelector(
  getProductCategories,
  getIncompleteProductCategoriesIds,
  (productCategories, ids) =>
    productCategories.filter(
      (productCategory) => !ids?.includes(productCategory?.id),
    ),
);
