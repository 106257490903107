import { normalize, schema } from 'normalizr';
import { apiDel, apiGet, apiPost, apiPut } from './helpers/api';
import { replace } from 'connected-react-router';
import toQueryString from '../utils/toQueryString';
import { fetchOrder } from './orderProcesses';
import { notifySubmitSucceeded } from './notifierProcesses';

const statement = new schema.Entity('statements');

const isValid = (value) => {
  return value && !isNaN(value);
};

export const fetchStatements = (dispatch, props) => {
  const currentPage = props?.currentPage || 1;
  const pageParams = props?.paged == true ? `page=${currentPage}` : '';
  const filterParams = toQueryString(props?.filters || {});

  dispatch({ type: 'STATEMENTS_FETCH_REQUESTED' });

  return apiGet(`/api/v1/statements?${filterParams}${pageParams}`).then(
    (response) => {
      dispatch({
        type: 'STATEMENTS_FETCHED',
        ...normalize(response.body.data, new schema.Array(statement)),
        totalPages: response.body.totalPages,
        currentPage: response.body.currentPage,
      });
    },
  );
};

export const fetchStatement = (dispatch, id) => {
  return apiGet(`/api/v1/statements/${id}`).then((response) => {
    dispatch({
      type: 'STATEMENT_FETCHED',
      ...normalize(response.body.data, statement),
    });
  });
};

export const fetchGeneralData = (dispatch, props) => {
  const { betweenDates } = props;
  const filters = betweenDates ? { betweenDates } : {};
  const filterParams = toQueryString(filters);

  return apiGet(`/api/v1/statements/general_data?${filterParams}`).then(
    (response) => {
      dispatch({
        type: 'GENERAL_DATA_FETCHED',
        data: response.body,
      });
    },
  );
};

export const fetchExpenseCategoriesRanking = (dispatch, props) => {
  const { betweenDates } = props;
  const filters = betweenDates ? { betweenDates } : {};
  const filterParams = toQueryString(filters);

  return apiGet(
    `/api/v1/statements/expenses_by_categories?${filterParams}`,
  ).then((response) => {
    dispatch({
      type: 'EXPENSE_CATEGORIES_RANKING_FETCHED',
      data: response.body,
    });
  });
};

export const createOrUpdateStatement = (values, dispatch, props) => {
  const handler = !values.id ? createStatement : updateStatement;
  return handler(values, dispatch, props);
};

export const createOrUpdateRevenue = (values, dispatch, props) => {
  const isModal = !!props?.closeStatementModal;
  const updatedValues =
    !!values?.orderStatement || !values?.statementCategoryId
      ? { ...values, statementCategoryId: props?.revenueCategoryId }
      : values;

  return createOrUpdateStatement(updatedValues, dispatch, props).then(() => {
    isModal
      ? (!!values?.orderId && fetchOrder(dispatch, values?.orderId),
        props?.closeStatementModal())
      : dispatch(replace('/statements/revenues'));
  });
};

export const createOrUpdateExpense = (values, dispatch, props) => {
  const isModal = !!props?.closeStatementModal;
  return createOrUpdateStatement(values, dispatch, props).then(() => {
    isModal
      ? (!!values?.orderId && fetchOrder(dispatch, values?.orderId),
        props?.closeStatementModal())
      : dispatch(replace('/statements/expenses'));
  });
};

const createStatement = (values, dispatch) => {
  return apiPost('/api/v1/statements')
    .send({ statement: values })
    .then((response) => {
      dispatch({
        type: 'STATEMENT_CREATED',
        ...normalize(response.body.data, statement),
      });
      notifySubmitSucceeded(dispatch, 'success', 'Registro adicionado!');
    });
};

const updateStatement = (values, dispatch, props) => {
  return apiPut(`/api/v1/statements/${props.statementId}`)
    .send({ statement: values })
    .then((response) => {
      dispatch({
        type: 'STATEMENT_UPDATED',
        ...normalize(response.body.data, statement),
      });
      notifySubmitSucceeded(dispatch, 'success', 'Registro atualizado!');
    });
};

export const deleteStatement = (dispatch, statement) => {
  const { id } = statement;
  const orderId = statement?.orderId;

  return apiDel(`/api/v1/statements/${id}`).then(() => {
    dispatch({
      type: 'STATEMENT_DELETED',
      id,
    });
    !!orderId && fetchOrder(dispatch, orderId);
  });
};

export const submitSearchFilter = (filters, dispatch) => {
  const { typeEq, dateFrom, dateTo, orderIdEq } = filters;
  const betweenDates = [dateFrom, dateTo];

  if (!isValid(dateFrom) || !isValid(dateTo)) return;

  dispatch({
    type: 'STATEMENTS_FILTERS_CHANGED',
    filters: { betweenDates, typeEq, orderIdEq },
  });
};
